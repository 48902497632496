.user-panel {
    background-color: #FAFAFA;
    min-height: 100vh;
    padding: 50px;
}

.panel {
    /* two columns */
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-gap: 25px;
}

.edit-tab {
    margin: 28px 0px;

    button {
        width: fit-content;
    }

    >div {
        width: 100%;
        display: flex;

        &.on-left {
            justify-content: flex-start;
            align-items: center;
            gap: 10px; 
        }
        &.on-right {
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
        }
    }
}

.logo {
    height: 40px;
}

.rounded-panel {
    width: 100%;

    &:not(.no-border) {
        border: 1px solid #CCCCCC;
        border-radius: 8px;
    }

    position: relative;

    padding: 38px 33px;

    background: #FFFFFF 0% 0% no-repeat padding-box;

    font: normal normal normal 16px/24px Inter,sans-serif;
    text-align: left;
    letter-spacing: 0px;
    color: #3B3B3B;

    transition: height 0.2s ease-in-out,
        width 0.2s ease-in-out;

    &.center-text {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0px 0px 0px 33px;
    }


    .overflow {
        display: inline-block;
        vertical-align: bottom;
        width: 400px;
        overflow-x: hidden;
    }

    .no-wrap {
        white-space: nowrap;
    }

    hr {
        margin: 26px 0px 26px -33px;
        border: 0.5px solid #CCCCCC;
        width: calc(100% + 66px);
    }

    span.no-select {
        user-select: none;
    }

    span.margin {
        margin-right: 10px;
    }

    span.label {
        font: normal normal bold 16px/24px Inter,sans-serif;
    }

    span.title {
        font: normal normal medium 21px/24px Inter,sans-serif;
    }

    a {
        text-align: left;
        text-decoration: underline !important;
        font: normal normal 16px/16px Inter,sans-serif;
        letter-spacing: 0px;
        color: #3B3B3B;
    }

    h1 {
        font: normal normal bold 18px/20px Inter;
    }

    h2 {
        font: normal normal bold 16px/24px Inter;
    }

    // two columns, right one is gray
    &.columns {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto;

        >div:first-child {
            margin-right: 25px;
        }

        >div:last-child {
            background-color: #FAFAFA;
            border-radius: 0px 8px 8px 0px;
            padding: 38px 33px;
            margin: -38px 0px -38px 5px;
        }
    }

    >.section {
        margin: 0px -33px;
        padding: 33px 33px;
        width: calc(100% + 66px);

        &.gray {
            background-color: #FAFAFA;
        }
    }

    div.warn {
        background-color: #FF92960D;
        border: 1px solid #FF9296;
        color: #682527;
        text-align: center;
        font: normal normal 600 14px/18px Inter;
        padding: 15px 29px;
    }

    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .user-score {
        width: 52px;
        height: 52px;

        border-radius: 50%;
        color: #3B3B3B;
        text-transform: uppercase;
        font: normal normal 800 18px/21px Inter;
        background: #F2F2F2 0% 0% no-repeat padding-box;

        /* center text */
        display: flex;
        justify-content: center;
        align-items: center;

        /* Positioning */
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .user-score-popup {
        height: 37px;

        position: absolute;
        top: 80px;

        background: #222222 0% 0% no-repeat padding-box;
        border-radius: 4px;

        opacity: 0;
        transition: opacity 0.05s ease-in-out;
        padding: 8px 12px 8px 12px;

        display: flex;
        text-align: center;
        font: normal normal normal 12px/15px Inter;
        letter-spacing: 0px;
        color: #FFFFFF;

        justify-content: center;
        align-items: center;

        z-index: 2;

        &.show {
            opacity: 1;
        }

        svg {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 15px;
            top: -15px;
        }
    }

    .action-checkbox {
        width: 24px;
        height: 24px;

        border: 1px solid #CCCCCC;
        border-radius: 4px;

        display: inline-flex;
        vertical-align: middle;

        margin: 0px 8px 3px 0px;

        svg {
            width: 20px;
            /* center svg */
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
        }
        &.MuiCheckbox-root {    
            .MuiTouchRipple-root {
                width: 24px;
                height: 24px;
            }

            color: #3B3B3B;

            &.Mui-checked {
                color: #3162FF;
            }
        }
    }

    .MuiChip-root {
        width: auto;
        height: 27px;

        margin: 0px 5px 5px 0px;
        white-space: nowrap;
        border-radius: 4px;

        text-align: left;
        font: normal normal 600 12px/15px Inter,sans-serif;
        letter-spacing: 0px;
        color: #3B3B3B;
        text-transform: uppercase;

        &.blue {
            color: #3162FF;
            border: 1px solid #3162FF;
        }
    }

    .MuiRadio-root {
        color: #3B3B3B;

        &.Mui-checked {
            color: #3B3B3B;
        }
    }


    .MuiCheckbox-root { 
        color: #3B3B3B;

        &.Mui-checked {
            color: #3B3B3B;
        }
    }
}

.edit-modal {
    width: 752px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    border: 1px solid transparent;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.icon {
    display: inline-flex;
    vertical-align: middle;
    align-items: baseline;
    cursor: pointer;
    margin: 0px auto;

    :hover {
        border-radius: 50%;
        box-shadow: 1px 1px 2px #00000059;
    }
}

.edit-icon {
    display: inline-flex;
    vertical-align: middle;
    align-items: baseline;
    cursor: pointer;
    margin-right: 8px;

    border: 1px solid #B5B5B5;
    border-radius: 3px;

    padding: 6px;

    &:hover {
        border: 1px solid #606060;
    }
}

.edit-tablet-icon {
    @extend .edit-icon;
    padding: 2px;
}

.gray-skin {
    color: #3B3B3B;

    .MuiTabs-flexContainer {
        border-color: #3B3B3B;
    }

    .MuiTab-root {
        font: normal normal normal 0.875rem/0.875rem Inter,sans-serif;
        color: #3B3B3B;
        text-transform: none;
        width: 150px;
        
        &.Mui-selected {
            color: #3B3B3B;
            font: normal normal bold 0.875rem/0.875rem Inter,sans-serif;
        }
    }
    &.dark-variant {
        .MuiTabs-flexContainer  {
            button {
                background-color: #F2F2F2; 

                &.Mui-selected {
                    background-color: white;
                }
            }
        }
    }

    .MuiToolbar-root {
        .hide-filter {
            margin-bottom: 12px;
        }

        #filter-button {
            border: 1px solid #CCCCCC;
            border-radius: 4px;

            .MuiButton-startIcon {
                display: none;
            }

            button {
                background-color: white;
                font: normal normal 600 13px/18px Inter,sans-serif;
                color: #3B3B3B;
                text-transform: none;
                padding: 8px 16px;
                width: 100%;

                &:hover {
                    background-color: white;
                }
            }
        }
        #export-button {
            border: 1px solid #CCCCCC;
            border-radius: 4px;

            .MuiButton-startIcon {
                display: none;
            }

            background-color: white;
            font: normal normal 600 13px/18px Inter,sans-serif;
            color: #3B3B3B;
            text-transform: none;
            padding: 8px 16px;

            &:hover {
                background-color: white;
            }
        }
    }

    .MuiTabs-indicator {
        background-color: #3B3B3B;
        height: 4px;
    }

    .MuiDivider-root {
        border-color: #cccccc;
        margin-top: -1px;
    }
}

.language-autocomplete {
    &.MuiFormControl-root {
        width: 100%;
        z-index: 2;
        margin-top: 32px;
    }
}

.community-members {    
    .MuiFormControl-root {
        min-width: 200px;
    }
    .MuiOutlinedInput-root {
        min-width: 200px;
    }
}

.custom-array-input {
    &:not(.normal-height) {
        .MuiSelect-select {
            height: 32px !important;
        }

        &.MuiFormControl-root {
            min-width: 150px;
            z-index: 2;
            margin-top: 15px;

            .MuiChip-root {
                margin: 0px 3px;
                border-radius: 5px;
            }
        }
        .Mui-selected {
            display: none;
        }

        .MuiOutlinedInput-root {
            height: 56px;
            padding-top: 2px;
        }
    }

    .MuiInputLabel-root {
        font-family: Inter, sans-serif;
        font-size: 1rem;

        &.Mui-focused {
            color: #00000099;
        }
    }

    .MuiOutlinedInput-root {
        background-color: white;

        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid #B5B5B5
            }
        }

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border: 1.5px solid #B5B5B5
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border: 1px solid #d3d3d3
        }   

        &.Mui-disabled {
            background-color: #F2F2F2;
        }
    }
}

.MuiChip-root {
    font: normal normal 600 12px/15px Inter,sans-serif;

    &:hover {
        box-shadow: 1px 1px 3px #00000029, inset 0px 0px 0px 1px #CCCCCC;
    }

    &.squere-chip {
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
                    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        margin: 3px 3px !important;
        border-radius: 5px !important;
        text-transform: uppercase;
        color: #606060;
        
        &:hover {
            color: #3B3B3B;
        }
        .icon {
            padding-right: 8px;
        }
    }

    &.big-chip {
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
        box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        
        margin: 3px 3px !important;
        height: 30px;
        letter-spacing: 0px;
        color: #3B3B3B;
        background-color: #E9E9E9;
        text-transform: uppercase;

        .icon {
            padding-right: 8px;
        }
    }
}

.outlined-button {
    &.MuiButton-root {
        text-transform: none;
        font: normal normal 600 13px/16px Inter;
        box-shadow: none;

        &.MuiButton-containedPrimary {
            color: #FFFFFF;
            background-color: #3162FF;
            box-shadow: none;

            &:hover {
                background-color: #1056E2;
            }

            &:disabled {
                background-color: #EBEBEB;
                color: #898989;
            }
        }

        &.MuiButton-containedSecondary {
            color: #000000;
            background-color: white;
            border: 1px solid #CCCCCC;
            box-shadow: 1px 1px 3px #00000029;

            &:hover {
                border: 1px solid #3B3B3B;
            }

            &:disabled {
                color: #898989;
                background-color: #EBEBEB;
                box-shadow: none;
            }
        }
        &.MuiButton-textError {
            background-color: #FF9296;
            color: #682527;

            &:hover {
                background-color: #f8abad;
            }
        }
    }
}

.no-shadow {
    .MuiPaper-root {
        box-shadow: none;
    }
}

.assigments-datagrid {
    font: normal normal normal 14px/24px Inter,sans-serif;

    &.MuiTable-root {
        .MuiTableHead-root {
            border-bottom: 2px solid #707070;
            .MuiTableRow-root .MuiTableCell-root {
                line-height: 1.1rem;
            }
        }
        
        .MuiTableRow-root {
            border: none;
            background-color: white;

            min-height: 68px;
            height: 68px;
            
            .MuiTableCell-root {
                line-height: 1.35;
            }

            :not(.small-row) & {
                min-height: 55px;
                height: 55px;
            }
        }
    }

    &.small.MuiTable-root {
        .MuiTableRow-root {
            min-height: 55px;
            height: 55px;
        }
    }

    .MuiRadio-root {
        color: #3B3B3B;

        &.Mui-checked {
            color: #3B3B3B;
        }
    }

    .MuiCheckbox-root { 
        color: #3B3B3B;

        &.Mui-checked {
            color: #3B3B3B;
        }
    }

    .MuiLink-root.RaLink-link {
        text-decoration: underline;
        color: #3B3B3B;
    }

    .list-page {
        .MuiPaper-root {
            border-radius: 10px;
            background-color: #FAFAFA !important;


            .MuiTable-root {
                box-shadow: 1px -1px 4px 1px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background-color: #FAFAFA !important;

                background-color: #FAFAFA;

                .MuiTableHead-root {
                    border-bottom: 1px solid #E9E9E9;

                    .RaDatagrid-headerCell {
                        border-radius: 10px;

                        background-color: #FAFAFA;
                    }
                }

                // last MuiTableRow-root 
                .MuiTableRow-root:last-child {
                    .RaDatagrid-rowCell {
                        border-bottom: 0px;
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }

                .RaDatagrid-tbody {
                    .MuiTableRow-root {
                        border-bottom: 0px;
                    }
                }
            }
        }
    }
}

.custom-pagination {
    .MuiInputBase-root {
        color: #606060;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        background-color: white;
        box-shadow: none;
    }
    
    .MuiTablePagination-displayedRows, 
    .MuiTablePagination-actions, 
    .MuiTablePagination-selectLabel, .MuiPagination-root .MuiPaginationItem-root {
        color: #606060;
        font: normal normal normal 14px/24px Inter, sans-serif;
    }

}

.w-md-editor {
  font-family: Inter, sans-serif !important;

  textarea, 
  .w-md-editor-text,
  .w-md-editor-text-pre,
  .w-md-editor-text-input,
  .w-md-editor-text-html {
    font-family: Inter, sans-serif !important;
  }
}

.wmde-markdown {
  font-family: Inter, sans-serif !important;
}